import { Image, Stack } from '@mantine/core'
import { MRT_ColumnDef } from 'mantine-react-table'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip'
import SubTopicsPageList from 'src/containers/CategoryTrails/SubTopicsPageList'
import { size } from 'src/variable/constant'
function SubTopicsPage() {
	const params = useParams()
	const { id } = params
	const [openTopic, setOpenTopic] = useState<any>({
		open: false,
		id: null,
		parent_id: id,
	})

	const columns: MRT_ColumnDef[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: size.zero,
		},
		{
			accessorKey: 'title',
			header: 'Topics',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip label={cell.getValue<string>()} maxWidth={size.text} />
				)
			},
			enableResizing: false,
		},
		{
			accessorKey: 'description',
			header: 'Description',
			size: size.zero,
			maxSize: size.medium,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip
						label={cell.getValue<string>()}
						maxWidth={size.medium}
					/>
				)
			},
		},
		{
			accessorKey: 'image',
			header: 'Image',
			size: size.zero,
			maxSize: size.long,
			Cell: ({ cell }) => {
				return (
					<Stack>
						<Image
							src={cell.getValue<string>()}
							height={cell.getValue<string>() ? 100 : 0}
							width={'auto'}
							sx={{ maxWidth: 100 }}
							fit="contain"
						/>
					</Stack>
				)
			},
		},
		{
			accessorKey: 'created_at',
			header: 'Created at',
			size: size.zero,
			maxSize: size.text,
		},
	]
	const fields = [
		{
			value: 'title',
			label: 'Title',
		},
	]
	const handleEdit = (row: any) => {
		setOpenTopic({ open: true, id: row?.original, parent_id: id })
	}

	return (
		<SubTopicsPageList
			columns={columns}
			id={id}
			fields={fields}
			handleEdit={handleEdit}
			setOpenTopic={setOpenTopic}
			openTopic={openTopic}
		/>
	)
}

export default React.memo(SubTopicsPage)
