import { Flex, Stack } from '@mantine/core'
import dayjs from 'dayjs'
import { MRT_ColumnDef, MRT_PaginationState } from 'mantine-react-table'
import { memo, useCallback, useEffect, useState } from 'react'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import CustomClearInput from 'src/components/CustomComponents/CustomClearInput'
import CustomSelect from 'src/components/CustomComponents/CustomSelect'
import TableComponent from 'src/components/TableComponent'
import { useLoading } from 'src/context/LoadingContext'
import { useModal } from 'src/context/ModalContext'
import { ConversationData } from 'src/interfaces'
import { filterNormal } from 'src/utils/filter'
import { formatDateTime } from 'src/variable/constant'
import ModalCreateConversation from './ModalCreateConversation'
import {
	deleteCategory,
	getListCategoryTrails,
} from 'src/apis/categoryTrailsAPI'

interface ConversationPageListProps {
	columns: MRT_ColumnDef[]
	fields: {
		value: string
		label: string
	}[]
	[key: string]: any
}
function ConversationPageList({ columns, fields }: ConversationPageListProps) {
	const { openError, openModal } = useModal()
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const [data, setData] = useState<ConversationData[]>([])
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 1,
		pageSize: 10,
	})
	const [value, setValue] = useState({
		field: fields[0].value,
		value: null,
	})
	const [total, setTotal] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(false)
	const [open, setOpen] = useState({
		open: false,
		data: null as ConversationData,
	})

	const handleGetData = useCallback(async () => {
		let arr: ConversationData[] = []
		let count: number = 0
		let filter = {}
		setLoading(true)
		try {
			filter = filterNormal(value)
			const res = await getListCategoryTrails({
				fields: ['$all'],
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				where: { ...filter, type: 'CONVERSATION' },
			})
			arr = res?.data?.results?.objects?.rows?.map((item, index) => ({
				...item,
				no: index + 1 + pagination.pageSize * (pagination.pageIndex - 1),
				created_at: dayjs(item?.created_at).format(formatDateTime),
			}))
			count = res?.data?.results?.objects?.count
		} catch (error) {
			openError(error)
		} finally {
			setData(arr)
			setTotal(count)

			setTimeout(() => {
				setLoading(false)
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination), JSON.stringify(value)])
	const handleSetValue = (e, key) => {
		if (key === 'field' && value.value === '') {
			setValue((pre) => ({ ...pre, [key]: e, value: null }))
		} else {
			setValue((pre) => ({ ...pre, [key]: e }))
		}
	}
	const handleChangePage = useCallback((value: number) => {
		setPagination((pre) => ({
			...pre,
			pageIndex: value,
		}))
	}, [])
	const handleEdit = useCallback((data) => {
		const { original } = data
		setOpen({ open: true, data: original })
	}, [])

	const handleDeleteConversation = useCallback(
		async (id) => {
			openLoadingContext()
			try {
				const res = await deleteCategory(id)
				if (res?.data?.code === 200) {
					await handleGetData()
					openModal({
						type: 'success',
						label: `You have successfully deleted this conversation !`,
					})
				}
			} catch (error) {
				openError(error)
			} finally {
				closeLoadingContext()
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleGetData],
	)

	const handleDelete = useCallback(
		(row: any) => {
			openModal({
				type: 'confirm',
				label: <div>Do you want to delete this conversation ?</div>,
				handleConfirm: () => {
					handleDeleteConversation(row?.original?.id)
				},
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleDeleteConversation],
	)

	useEffect(() => {
		handleGetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination)])
	useEffect(() => {
		let timer
		if (value.value !== null) {
			timer = setTimeout(() => {
				if (pagination.pageIndex === 1) {
					handleGetData()
				} else handleChangePage(1)
			}, 500)
		}
		return () => clearTimeout(timer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(value)])

	const _renderTop = () => (
		<Flex gap={8} justify="space-between" sx={{ flexWrap: 'wrap' }}>
			<Flex gap={8} sx={{ flexWrap: 'wrap' }}>
				<CustomSelect
					value={value.field}
					data={fields}
					onChange={(e) => {
						handleSetValue(e, 'field')
					}}
					sx={{
						input: {
							color: 'black !important',
							fontWeight: 600,
						},
					}}
				/>
				<CustomClearInput
					value={value.value}
					onChange={(e) => handleSetValue(e, 'value')}
					placeholder={'Search'}
				/>
			</Flex>

			<CustomButton
				variant="outlined"
				sx={{ height: '36px', whiteSpace: 'nowrap', minWidth: '240px' }}
				onClick={() => setOpen({ open: true, data: null })}
			>
				Create
			</CustomButton>
		</Flex>
	)

	return (
		<Stack sx={{ width: '100%' }}>
			{_renderTop()}
			<TableComponent
				columns={columns}
				total={total}
				data={data}
				pagination={pagination}
				loading={loading}
				handleChangePage={handleChangePage}
				handleEdit={handleEdit}
				handleDelete={handleDelete}
			/>
			{open.open && (
				<ModalCreateConversation
					open={true}
					data={open.data}
					onClose={() => setOpen({ open: false, data: null })}
					handleGetData={handleGetData}
					pagination={pagination}
					handleChangePage={handleChangePage}
				/>
			)}
		</Stack>
	)
}

export default memo(ConversationPageList)
