import { Flex, Stack, Tooltip } from '@mantine/core'
import dayjs from 'dayjs'
import { MRT_ColumnDef, MRT_PaginationState } from 'mantine-react-table'
import { memo, useCallback, useEffect, useState } from 'react'
import { deleteNoti, getListNoti, pushNoti } from 'src/apis/notificationAPI'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import CustomClearInput from 'src/components/CustomComponents/CustomClearInput'
import CustomSelect from 'src/components/CustomComponents/CustomSelect'
import TableComponent from 'src/components/TableComponent'
import { useLoading } from 'src/context/LoadingContext'
import { useModal } from 'src/context/ModalContext'
import { NotificationData } from 'src/interfaces'
import { filterNormalIlike } from 'src/utils/filter'
import { formatDateTime } from 'src/variable/constant'
import ModalCreateNotification from './ModalCreateNotification'
import { IconSend } from '@tabler/icons-react'

interface NotificationPageListtProps {
	columns: MRT_ColumnDef[]
	fields: {
		value: string
		label: string
	}[]
	[key: string]: any
}
function NotificationPageList({ columns, fields }: NotificationPageListtProps) {
	const { openError, openModal } = useModal()
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const [data, setData] = useState<NotificationData[]>([])
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 1,
		pageSize: 10,
	})
	const [value, setValue] = useState({
		field: fields[0].value,
		value: null,
	})
	const [total, setTotal] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(false)
	const [open, setOpen] = useState({
		open: false,
		data: null as NotificationData,
	})

	const handleGetData = useCallback(async () => {
		let arr: NotificationData[] = []
		let count: number = 0
		let filter = {}
		setLoading(true)
		try {
			filter = filterNormalIlike(value)
			const res = await getListNoti({
				fields: ['$all'],
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				where: { ...filter },
			})
			arr = res?.data?.results?.objects?.rows?.map((item, index) => ({
				...item,
				no: index + 1 + pagination.pageSize * (pagination.pageIndex - 1),
				created_at: dayjs(item?.created_at).format(formatDateTime),
			}))
			count = res?.data?.results?.objects?.count
		} catch (error) {
			openError(error)
		} finally {
			setData(arr)
			setTotal(count)

			setTimeout(() => {
				setLoading(false)
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination), JSON.stringify(value)])
	const handleSetValue = (e, key) => {
		if (key === 'field' && value.value === '') {
			setValue((pre) => ({ ...pre, [key]: e, value: null }))
		} else {
			setValue((pre) => ({ ...pre, [key]: e }))
		}
	}
	const handleChangePage = useCallback((value: number) => {
		setPagination((pre) => ({
			...pre,
			pageIndex: value,
		}))
	}, [])
	const handleEditNoti = useCallback((data) => {
		const { original: NotificationData } = data
		setOpen({ open: true, data: NotificationData })
	}, [])
	const handleSendNoti = useCallback(
		async (id) => {
			openLoadingContext()
			try {
				const res = await pushNoti(id)
				if (res?.data?.code === 200) {
					openModal({
						type: 'success',
						label: `You have successfully sent this notification !`,
					})
				}
			} catch (error) {
				openError(error)
			} finally {
				closeLoadingContext()
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)
	const handleDeleteNoti = useCallback(
		async (id) => {
			openLoadingContext()
			try {
				const res = await deleteNoti(id)
				if (res?.data?.code === 200) {
					await handleGetData()
					openModal({
						type: 'success',
						label: `You have successfully deleted this notification !`,
					})
				}
			} catch (error) {
				openError(error)
			} finally {
				closeLoadingContext()
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleGetData],
	)
	const handleSend = useCallback(
		(row: any) => {
			openModal({
				type: 'confirm',
				label: <div>Do you want to send this notification ?</div>,
				handleConfirm: () => {
					handleSendNoti(row?.original?.id)
				},
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)
	const handleDelete = useCallback(
		(row: any) => {
			openModal({
				type: 'confirm',
				label: <div>Do you want to delete this notification ?</div>,
				handleConfirm: () => {
					handleDeleteNoti(row?.original?.id)
				},
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleDeleteNoti],
	)

	useEffect(() => {
		handleGetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination)])
	useEffect(() => {
		let timer
		if (value.value !== null) {
			timer = setTimeout(() => {
				if (pagination.pageIndex === 1) {
					handleGetData()
				} else handleChangePage(1)
			}, 500)
		}
		return () => clearTimeout(timer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(value)])

	const _renderCustomActions = useCallback(
		(row) => (
			<Stack sx={{ order: 21 }}>
				<Tooltip label="Send notification">
					<IconSend
						style={{ cursor: 'pointer' }}
						onClick={() => handleSend(row)}
					/>
				</Tooltip>
			</Stack>
		),
		[handleSend],
	)

	return (
		<Stack sx={{ width: '100%' }}>
			<Flex gap={8} justify="space-between" sx={{ flexWrap: 'wrap' }}>
				<Flex gap={8} sx={{ flexWrap: 'wrap' }}>
					<CustomSelect
						value={value.field}
						data={fields}
						onChange={(e) => {
							handleSetValue(e, 'field')
						}}
						sx={{
							input: {
								color: 'black !important',
								fontWeight: 600,
							},
						}}
					/>
					<CustomClearInput
						value={value.value}
						onChange={(e) => handleSetValue(e, 'value')}
						placeholder={'Search'}
					/>
				</Flex>

				<CustomButton
					variant="outlined"
					sx={{ height: '36px', whiteSpace: 'nowrap', minWidth: '240px' }}
					onClick={() => setOpen({ open: true, data: null })}
				>
					Create
				</CustomButton>
			</Flex>
			<TableComponent
				columns={columns}
				total={total}
				data={data}
				pagination={pagination}
				loading={loading}
				handleChangePage={handleChangePage}
				handleEdit={handleEditNoti}
				handleDelete={handleDelete}
				_renderCustomActions={_renderCustomActions}
			/>
			{open.open && (
				<ModalCreateNotification
					open={true}
					data={open.data}
					onClose={() => setOpen({ open: false, data: null })}
					handleGetData={handleGetData}
					pagination={pagination}
					handleChangePage={handleChangePage}
				/>
			)}
		</Stack>
	)
}

export default memo(NotificationPageList)
