import { memo } from 'react'
import { MRT_ColumnDef } from 'mantine-react-table'
import { size } from 'src/variable/constant'
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip'
import ConversationPageList from 'src/containers/Conversation/ConversationPageList'

function ConversationPage() {
	const columns: MRT_ColumnDef[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: size.zero,
		},
		{
			accessorKey: 'title',
			header: 'Title',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip label={cell.getValue<string>()} maxWidth={size.long} />
				)
			},
		},
		{
			accessorKey: 'created_at',
			header: 'Created at',
			size: size.zero,
			maxSize: size.text,
		},
	]
	const fields = [
		{
			value: 'title',
			label: 'Title',
		},
	]
	return <ConversationPageList columns={columns} fields={fields} />
}

export default memo(ConversationPage)
